/**
 * Convert a string to float, handling white space as thousand-separator
 * @param s
 * @return a number. In case where parsing result in NaN, the function returns 0 instead
 */
export function stringToFloat(s?: string | number): number {
  if (typeof s === 'number') {
    return s
  }

  const asFloat = parseFloat(s?.replaceAll(' ', ''))
  if (isNaN(asFloat)) {
    return 0
  }
  return asFloat
}

/**
 * Reducer for summing a field on objects, using the above stringToFloat
 * @param propName the field to use for summing
 */
export function sumStringFloatsReducer(propName: string): (acc: number, v: any) => number {
  return function reducer(acc: number, v: { [key: string]: any }): number {
    return acc + stringToFloat(v[propName])
  }
}

/**
 * Convert bytes to human-readable format
 * @param bytes the number of bytes
 */
export function bytesToHumanReadable(bytes?: number): string {
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB']
  let unitIndex = 0
  if (bytes == null || bytes === 0) {
    return '0 B'
  }
  while (bytes > 1000) {
    bytes /= 1000
    unitIndex++
  }
  return `${bytes.toFixed(1)} ${units[unitIndex]}`
}
